"use strict";
import './styles/style.scss';

import {
    setHeader,
    hidePreloader,
    setTopImage,
    hideLink,
    setScrollTop,
    setMapCookies,
    setParams,
    initScroll,
} from "./js/layoutfunctions.js";

import { mlNav } from "./js/mlNav.js";
import { setMobileNav } from "./js/setMobileNav";


import cookieconsent from 'cookieconsent';
const feather = require('feather-icons')



jQuery(function () {

    feather.replace();
    
    mlNav();
    setHeader();
    setMobileNav();

    if ($(".fs-slider").length < 1) {
        hidePreloader();
    }

    if ($("#top-image").length) {
        setTopImage($('#top-image'), 'img');
    }

    $(".hide-link-title").each(function () {
        hideLink($(this));
    });



    $(window).on('scroll', function () {
        setHeader();
        setScrollTop();
        setTopImage($('#top-image'), 'img');
    });

    $(window).on('resize', function () {
        setHeader();
    });



    //cookies
    $('.allowMap').on('click', function (e) {
        e.preventDefault();
        setMapCookies($(this).hasClass('true'));
    });



    //consent
    var ctext = $('#cookies-text').html();
    var cok = $('#cookies-ok').html();
    window.addEventListener("load", function () {
        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#f7f7f7"
                },
                "button": {
                    "background": "#EA5B0C"
                }
            },
            "position": "bottom-left",
            "content": {
                "message": ctext,
                "dismiss": cok,
                "link": "",
                "href": ""
            }
        })
    });

    //sections scrolls
    initScroll();

    $('.sections-nav').on('click', '.go-to', function(e){
        e.preventDefault()
        const alias = $(this).attr('data-attr-alias');
        setParams({alias: alias});
    });
    
    $('#scroll-top').on('click', function (e) {
        e.preventDefault();
        setParams('');
        // $('html, body').animate({
        //     scrollTop: 0
        // }, 500, function(){
        //     setTimeout(function(){
        //         $('#scroll-top').removeClass('isvis');
        //     }, 100);
        // });
    });

});
