'use strict'
import $ from "jquery";
import {elemPosPerc} from './elemPos.js';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';

//HEADER
export let setHeader = () => {
    const topOffset = $(window).scrollTop();
    if(topOffset > 0){
        $('header').addClass('sticky-top');
    }
    else{
        $('header').removeClass('sticky-top');
    }

    if(topOffset > 44){
        $('header').addClass('sticky-bottom');
    }
    else{
        $('header').removeClass('sticky-bottom');
    }

};

//FOOTER
export let setFooter = () => {
    var fHeight = $('footer').outerHeight();
    $('.wrapper').css('padding-bottom', fHeight + 'px');
};

//PRELOADER
export let hidePreloader = () => {
    $("#preloader").find('#loader-holder').addClass('ishid');
    $('#preloader').fadeOut(750, () => {
       // $('body').removeClass('scroll-locked');
    });
};

export let showPreloader = () => {
    $('body').addClass('scroll-locked');
    $("#preloader").find('#loader-holder').removeClass('ishid');
    $('#preloader').fadeIn(750);
};

//TOP IMAGE
export let setTopImage = (elem, image) => {
    if(elem.length){
        const perc = elemPosPerc(elem, 100, 'top', true);
        const scale = 1 + 0.2 * perc;
        $(elem).find(image).css('transform', 'scale(' + scale + ')');

        if ($(elem).hasClass('transparent')) {
            if ($(elem).hasClass('bright')) {
                $('header').addClass('bright');
            }
            if ($(elem).hasClass('dark')) {
                $('header').addClass('dark');
            }
        }
    }
};

//Mobilenav

export let toggleMobileNav = () => {
    if($('#mobile-nav').hasClass('open')){
        hideMobileNav();
    }
    else{
        showMobileNav();
    }
}

export let toggleMobileMlNav = () => {
    if($('#header-wrapper').hasClass('nav-open')){
        hideMobileMlNav();
    }
    else{
        showMobileMlNav();
    }
}

export let showMobileNav = () => {
    $('header').addClass('nav-open');
    $('#mobile-nav').addClass('open');
    $('body').addClass('scroll-locked');

    if(!$('.menu-icon').hasClass('open')){
        $('.menu-icon').addClass('open');
    }
};

export let hideMobileNav = () => {
    if($('#mobile-nav').hasClass('open')){
        
        $('header').removeClass('nav-open');
        $('#mobile-nav').removeClass('open');
        $('body').removeClass('scroll-locked');

        if($('.menu-icon').hasClass('open')){
            $('.menu-icon').removeClass('open');
        }
    }
};


export let showMobileMlNav = () => {
    if($('header').hasClass('sticky')){
        $('header').removeClass('tiny');
    }
    $('#header-wrapper').addClass('nav-open');
    $('#header-wrapper').scrollTop(0);
    $('body').addClass('scroll-locked');

    if(!$('.menu-icon-mlnav').hasClass('open')){
        $('.menu-icon-mlnav').addClass('open');
    }
};

export let hideMobileMlNav = () => {
    if($('#header-wrapper').hasClass('nav-open')){
        if($('header').hasClass('sticky')){
            $('header').addClass('tiny');
        }
        $('#header-wrapper').removeClass('nav-open');
        $('body').removeClass('scroll-locked');

        if($('.menu-icon-mlnav').hasClass('open')){
            $('.menu-icon-mlnav').removeClass('open');
        }
    }
};

// HIDE LINK TITLES
export let hideLink = (element) => {
    const title = $(element).attr('title');
    element.on('mouseenter', function(){
        $(this).attr('title', '');
    });
    element.on('mouseleave', function(){
        $(this).attr('title', title);
    });
};

//set bg position on scroll

export let setCenterSkPosScroll = (element) => {
    if(element.length){
        const perc = elemPosPerc(element, 100, 'top', true)
        const pos = perc*100;
        element.css('background-position-y', pos + '%');
    }
};

export let scrollTo = (alias) => {
    const ec = $('#'+alias).length;
    let st = 0;
    if(ec && ec > 0){
        st = $("#"+alias+"").offset().top - 100;
    }
    $([document.documentElement, document.body]).animate({
        scrollTop: st
    }, 500);
};

//scroll top button
let lastScrollTop = 0;

export let setScrollTop = () => {
    const wst = $(window).scrollTop();
    if(wst < lastScrollTop && wst > 500){
        $('#scroll-top').addClass('isvis');
    }
    else{
        $('#scroll-top').removeClass('isvis');
    }
    lastScrollTop = wst;
}

//set url params and scrolls

const history = createBrowserHistory();
export let initScroll = () => {
    const initparams = queryString.parse(location.search);
    const initAlias = initparams.goto;
    if(initAlias !== undefined && initAlias != ''){
        scrollTo(initAlias);
    }
}

const unlisten = history.listen((location, action) => {
    const initparams = queryString.parse(location.location.search);
    const alias = initparams.goto;
    scrollTo(alias);
})

export let setParams = ({alias: goto}) => {
    const parsed = queryString.parse(location.search);
    const initAl = parsed.goto;
    let update = false;

    if(goto !== undefined){

        if(initAl !== undefined && goto == initAl){
            scrollTo(goto);
        }
        else{
            parsed.goto = goto;
            update = true;
        }

    }
    else{
        parsed.goto = '';
        update = true;
    }
    
    if(update){
        const stringified = queryString.stringify(parsed, {
            skipEmptyString: true
        });

        history.push({
            pathname: location.pathname,
            search: stringified
        })
    }
    else{
        return;
    }
}

//COOCKIES

export let setMapCookies = (setTrue) => {
    setTrue ? Cookies.set('mc', 'true') : Cookies.remove('mc');
}